.left-column,
.right-column {
  overflow: hidden;
  margin-bottom: 1rem;

  .card {
    width: 200px;
  }

  .comments-component {
    height: 100%;
  }
}

.left-column {
  padding-right: 0;
}

.left-column ~ .col {
  overflow: auto;
}

.right-column {
  padding-left: 0;
}

.column-toggle {
  .btn-dark:not(:disabled):not(.disabled):active:focus,
  .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus,
  .btn-light:focus,
  .btn-light.focus {
    box-shadow: none;
  }

  .btn-dark:not(:disabled):not(.disabled):active,
  .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    background-color: var(--brand_color);
    border-color: var(--brand_color);
  }
}
