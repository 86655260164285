.template-tree {
  .tree-children {
    padding-left: 15px;
  }

  .node-content-wrapper {
    width: 100%;
  }

  tree-viewport {
    overflow-x: hidden;
  }

  .node-dragging {
    .template-level-1,
    .template-level-2,
    .template-level-3,
    .template-level-4,
    .template-level-5 {
      background: rgba(250, 183, 21, 0.22) !important;
      border: 1px dashed grey;
    }
  }

  .template-level-1,
  .template-level-2 {
    background: rgba(237, 237, 237, 1);
  }

  .template-level-3 {
    background: #f8f8f8;
  }

  .node-wrapper {
    align-items: center;
  }

  .node-content-wrapper:hover,
  .node-content-wrapper-focused {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .template-level-4 {
    background: #f5f5e5;
  }

  .template-level-5 {
    background: #f5f5d5;
  }

  .is-dragging-over {
    background: rgba(250, 183, 21, 0.48) !important;
    border: none;

    .template-level-1,
    .template-level-2,
    .template-level-3,
    .template-level-4,
    .template-level-5 {
      background: transparent;
    }
  }

  &:not(.with-expanders) {
    .toggle-children-placeholder,
    .toggle-children {
      display: none;
    }

    tree-node-expander {
      display: none;
    }
  }

  &.with-expanders {
    tree-node-expander {
      position: relative;
      top: -2px;
      left: 20px;
      z-index: 2;
    }
  }

  &.with-drag-and-drop {
    .node-content-wrapper {
      cursor: move;

      .template-level-1 {
        cursor: initial;
      }
    }
  }
}

.template-tree-fixed {
  .node-content-wrapper {
    width: 100%;
    cursor: initial;
  }
}

.node-content-wrapper-active, .node-content-wrapper.node-content-wrapper-active:hover, .node-content-wrapper-active.node-content-wrapper-focused {
  background: none !important;
  box-shadow: none !important;
}
