.pagination {
  .page-link {
    color: $blue;
    text-decoration: none !important;
  }

  .page-item.active .page-link {
    background-color: $blue;
    border-color: $blue;
  }

  .page-link:focus {
    box-shadow: 0 0 0 0.2rem $blue;
  }
}
