@mixin tag-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  &[href] {
    @include hover-focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }
}

.tag {
  color: $white !important;
  cursor: pointer;
  background-color: $gray-600;
  display: inline-block;
  font-size: $tag-font-size;
  font-weight: $tag-font-weight;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: $tag-padding-y $tag-padding-x;
  // line-height: 1;
  text-align: center;
  text-transform: none;
  vertical-align: baseline;
  white-space: nowrap;
  // @include border-radius($badge-border-radius);

  @include hover-focus {
    text-decoration: none;
    background-color: darken($gray-600, 10%);
  }

  &.dark {
    background-color: $blue;
  }

  &.light {
    background-color: var(--content_secondary_background_color);
    color: $gray-900 !important;
  }

  .prefix {
    color: $gray-200;
    margin-right: 0.3rem;
    text-transform: uppercase;
  }

  .times {
    color: $gray-200;
    margin-left: 0.1rem;
    font-weight: 600;
  }
}

@each $color, $value in $theme-colors {
  .tag-#{$color} {
    @include tag-variant($value);
  }
}
