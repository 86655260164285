/*
 *  IE10 & 11 Flexbox fix
 */

@media all and (-ms-high-contrast: none) {
  html {
    display: flex;
    flex-direction: column;
  }
}
