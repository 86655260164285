@keyframes loading {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: -100%;
  }
}

.mat-form-table {
  .mat-header-cell {
    padding: 5px;
  }

  .mat-cell {
    padding: 10px 5px;
  }

  .mat-header-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-cell:first-of-type {
    padding-left: 0 !important;
  }

  .mat-header-cell:last-of-type,
  .mat-footer-cell:last-of-type,
  .mat-cell:last-of-type {
    padding-right: 0 !important;
  }
}

.table-container {
  overflow-x: auto;
}

.app-mat-table {
  border-collapse: separate;
  width: 100%;

  .mat-row {
    white-space: nowrap;
  }

  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell {
    padding-left: 5px;
    padding-right: 5px;
    white-space: nowrap;
  }

  .mat-footer-cell {
    border-color: #E0E0E0; // fix for chrome bug: https://github.com/mui-org/material-ui/issues/9149
  }

  .left-bordered {
    border-left: 1px solid $gray-200;
  }

  .right-bordered {
    border-right: 1px solid $gray-200;
  }

  .mat-header-cell {
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    border-bottom: none;
  }

  .mat-cell {
    border-bottom: none;
  }

  .cell-collapse {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-header-cell:first-of-type,
  .mat-header-cell:last-of-type,
  .mat-footer-cell:first-of-type,
  .mat-footer-cell:last-of-type,
  .mat-cell:first-of-type,
  .mat-cell:last-of-type {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mat-header-cell:last-of-type:not([colspan]),
  .mat-footer-cell:last-of-type:not([colspan]),
  .mat-cell:last-of-type:not([colspan]) {
    text-align: right;
  }

  &:not(.no-striped-rows) .mat-row:nth-child(odd) {
    background-color: var(--content_primary_background_color) !important;
  }
}

.app-mat-table.auto-width {
  width: inherit !important;
}

.app-mat-table-loading {
  tbody {
    transition: opacity 0.4s ease;
    opacity: 1;
  }

  tbody.dummy-rows {
    opacity: 0;
  }

  .hidden {
    display: none;
  }
}

.app-mat-table-loading.table-is-loading {
  tbody {
    opacity: 0;
  }

  tbody.dummy-rows {
    opacity: 1;
  }

  .mat-header-cell {
    color: #888;
  }

  .mat-cell {
    font-family: BLOKK;
    color: #aaa;
  }

  .btn,
  button {
    visibility: hidden;
  }

  .mat-row {
    animation: loading 2s infinite linear;
    will-change: background-position;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba($white, 1) 10%, rgba(255, 255, 255, 0) 18%);
    background-repeat: no-repeat;
    background-size: 200% 100%;
  }
}

.app-mat-table-loading.table-is-empty {
  .mat-cell {
    @extend .alert, .alert-warning;
    text-align: left;
  }

  tbody:not(.loading-rows),
  thead,
  tfoot {
    display: none;
  }
}

.app-mat-table-loading.table-is-error {
  .mat-cell {
    @extend .alert, .alert-danger;
    text-align: left;
  }

  tbody:not(.error-rows),
  thead,
  tfoot {
    display: none;
  }
}

.booking-items-table,
.invoice-items-table {
  label {
    display: inline;
  }

  th {
    font-weight: bold;
  }

  .mat-header-row {
    height: 32px;
  }

  .mat-form-field .mat-error {
    display: none;
  }

  .mat-form-field-wrapper {
    margin-bottom: -1.4em;
  }

  .mat-header-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-cell:first-of-type {
    padding-left: 0 !important;
  }

  .price-column,
  .action-column {
    padding-right: 0 !important;
  }
}

.booking-items-table {
  .mat-header-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-cell:first-of-type {
    padding-left: 10px !important;
  }

  .mat-header-cell:last-of-type,
  .mat-footer-cell:last-of-type,
  .mat-cell:last-of-type {
    padding-right: 10px !important;
  }
}

.mat-header-cell.text-right,
.mat-header-right {
  text-align: right !important;

  .mat-sort-header-container {
    float: right;
    display: flex;
    flex-direction: row-reverse;

    .mat-sort-header-arrow {
      margin: 0 6px 0 0;
    }
  }
}

.mat-header-cell.text-center,
.mat-header-center {
  text-align: center !important;

  .mat-sort-header-container {
    display: flex;
    justify-content: center;
  }

  .mat-sort-header-arrow {
    margin: 0 -10px 0 0;
    position: relative;
    left: 10px;
  }
}

.mat-row.has-row-action {
  cursor: pointer;

  &:hover {
    background-color: var(--content_secondary_background_color)!important;
  }
}

.mat-header-cell.mat-table-sticky-border-elem-left,
.mat-header-cell.mat-table-sticky-border-elem-right {
  min-width: 58px;
  width: 58px;
}

.mat-cell.mat-table-sticky-border-elem-right {
  border-left: 1px solid $gray-200;
  white-space: nowrap;
}

.mat-cell.mat-table-sticky-border-elem-left {
  border-right: 1px solid $gray-200;
  white-space: nowrap;
}
