@font-face {
  font-family: 'Overpass';
  src: url('overpass-bold.otf'),
    url(overpass-bold.otf) format('otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-bold-italic.otf'),
  url(overpass-bold-italic.otf) format('otf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-italic.otf'),
  url(overpass-italic.otf) format('otf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-light.otf'),
  url(overpass-italic.otf) format('otf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-light-italic.otf'),
  url(overpass-light-italic.otf) format('otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-regular.otf'),
  url(overpass-regular.otf) format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Overpass';
  src: url('overpass-semibold.otf'),
  url(overpass-semibold.otf) format('otf');
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Overpass';
  src: url('overpass-semibold-italic.otf'),
  url(overpass-semibold-italic.otf) format('otf');
  font-weight: 600;
  font-style: italic;
}
