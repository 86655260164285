.modal {
  .modal-content {
    border: none;
    border-radius: inherit;
    padding: 0.225rem 0.5rem;

    .modal-header {
      border: none;
      border-radius: inherit;

      h6 {
        text-transform: uppercase;
      }
    }

    .modal-body {
      border: none;
      border-radius: inherit;

      font-size: 0.8125rem;

      .form-modal-footer {
        margin: 2rem -1rem -1rem -1rem;
      }
    }

    .modal-footer {
      border: none;
      border-radius: inherit;
    }
  }
}

/* TODO JHH: There is probably a btter place for this. Headlines in modals...: */
.mat-dialog-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.mat-dialog-content {
  padding-bottom: .75rem !important;
}
