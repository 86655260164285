// Font
$font-size-content: 0.8125rem;
$font-size-header-xs: 1rem;
$font-size-header-xxs: .9125rem;
$font-size-header: 1.25rem;
$font-size-page-header: 2.25rem;
$font-size-nav-item: 0.75rem;

// Common
$layout-transition-speed: 0.5s !default;
$padding-x: 1.25rem;
$padding-y: 1.25rem;

// Button bottom padding offset
$input-btn-padding-bottom-offset-fix: 0.075rem;

// Sidebar
$sidebar-width: 240px;
$sidebar-minimized-width: 70px;
$sidebar-background-color: $gray-700;

// Navigation
$navigation-height: 64px;
$navigation-font-size: 0.875rem;
$navigation-font-weight: 400;
$navigation-item-border-active-width: 4px;
$navigation-item-border-active: $navigation-item-border-active-width solid $cyan;
$navigation-item-background-active: darken($sidebar-background-color, 7);
$navigation-item-background-hover: darken($sidebar-background-color, 7);

// Tag
$tag-font-size: 75%;
$tag-font-weight: 600;
$tag-padding-y: 0.35rem;
$tag-padding-x: 0.4rem;
$tag-border-radius: $border-radius;

// Datepicker
$datepicker-footer-width: 200px;
