ul {
  &.inline {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
    }
  }

  &.commaseparated {
    @extend ul, .inline;

    li {
      &:after {
        content: ',';
        display: inline-block;
        margin-right: 0.3em;
      }

      &:last-child:after {
        content: none;
      }
    }
  }
}
