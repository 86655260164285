@import 'bootstrap-variables';

$tabHeight: 38px;
$boxShadowSpace: 2px;

.mat-nav-tabs,
mat-tab-group {
  margin-top: -$boxShadowSpace;
  margin-left: -$boxShadowSpace;

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-link-active,
  .mat-tab-label-active {
    opacity: 1 !important;
    background-color: $white !important;
    color: $gray-800;
  }

  .mat-tab-label-active {
    text-decoration: none;
    cursor: unset;
  }

  .mat-tab-link,
  .mat-tab-label {
    opacity: 1 !important;
    background-color: var(--content_secondary_background_color);
    color: $gray-800;
    height: $tabHeight;
    font-weight: bold;
    margin-right: 4px;
    box-shadow: none;
    text-transform: uppercase;
    border-bottom: none;
  }

  .mat-ink-bar {
    visibility: hidden !important;
  }

  .mat-tab-label-container,
  .mat-tab-link-container {
    padding-left: $boxShadowSpace;
    padding-top: $boxShadowSpace;
  }
}

.mat-tab-nav-bar + .card,
mat-tab-group + .card {
  border-top-left-radius: 0;
}

.mat-nav-tabs {
  border-bottom: none;
}
