@import "bootstrap-variables";

body,
app-root {
  background-color: var(--navigation_background_color) !important;
}

body.auth-page {
  overflow-x: hidden;
  padding: 20px;
}

app-auth-page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;

  app-global-alerts {
    position: fixed;
    top: 0;
  }

  app-logo {
    width: 200px !important;
  }

  .auth-main-container {
    width: 100%;
    max-width: 800px;

    .auth-container {
      display: flex;
      flex-direction: row;
      border-radius: 6px;
      margin-bottom: 1rem;

      .auth-info {
        flex: 1;
        padding: 1.25rem;
        background-color: #092530;
        color: $white;
        border-radius: 2px 0 0 2px;

        h3,h4 {
          font-weight: bold;
        }

        @media (max-width: 700px) {
          display: none;
        }
      }

      .auth-form {
        flex: 1;
        padding: 1.25rem;
        background-color: $white;
        border-radius: 0 2px 2px 0;

        @media (max-width: 700px) {
          border-radius: 2px;
        }
      }
    }
  }

  .auth-links {
    display: flex;
    color: $white;

    .auth-link {
      margin-right: 0.5rem;
      color: $white;
      cursor: pointer;
    }

    .auth-language {
      margin-right: 0.5rem;
      text-transform: uppercase;
      cursor: pointer;

      &:hover,
      &.auth-language-active {
        text-decoration: underline;
      }

      &:nth-of-type(1) {
        margin-left: 0.5rem;
      }
    }
  }
}

body.auth-page-iframe {
  overflow-x: hidden;

  app-auth-page-layout {
    justify-content: start;

    .auth-main-container {
      width: 100%;
      max-width: 500px;

      .auth-container {
        display: flex;
        flex-direction: row;
        border-radius: none;
        box-shadow: none;
        margin-bottom: 1rem;

        .auth-info {
          display: none;
        }

        .auth-form {
          color: $white;
          background-color: $accounto-dark_blue;

          .mat-form-field-flex {
            color: $black;
            background-color: $white;
          }

          .mat-form-field-underline {
            &::before {
              background-color: $white;
            }

            .mat-form-field-ripple {
              background-color: $white;
            }
          }

          .mat-form-field-invalid {
            .mat-form-field-ripple {
              background-color: #f44336;
            }
          }
        }
      }
    }
  }
}
