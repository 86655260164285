:root {
  --navigation_background_dark_color: #0A3E4E;
  --navigation_background_item_dark_color: #0A2A35;
  --navigation_background_item_active_color: #0F404F;
  --navigation_font_color: #fff;
  --brand_color: blue;
  --content_primary_background_color: #f9f9f9;
  --content_secondary_background_color: #dbdcdc;
}

body {
  transition: background-color 200ms linear;
}

.color-brand {
  color: var(--brand_color) !important;
}

.bg-brand {
  background: var(--brand_color) !important;
}

.text-primary {
  color: var(--brand_color) !important;
}

/* Fonts */
@import '../assets/fonts/overpass/overpass';

/* Loaded before rest */
@import 'bootstrap-variables';
@import 'bootstrap/scss/bootstrap';
@import 'mixins';
@import 'variables';
@import 'browserhacks';
@import 'pace';
@import 'animate';
@import 'ngx-toastr/toastr';
@import 'ngx-toastr/toastr-bs4-alert';
@import "@ali-hm/angular-tree-component/css/angular-tree-component";
@import 'quill/dist/quill.core';
@import 'quill/dist/quill.bubble';
@import 'quill/dist/quill.snow';
@import '@siemens/ngx-datatable/index';
@import '@siemens/ngx-datatable/themes/material';
@import '@siemens/ngx-datatable/assets/icons';

/* Rest sorted alphabetically */
@import 'alert';
@import 'badge';
@import 'breadcrumb';
@import 'buttons';
@import 'cards';
@import 'columns';
@import 'datepicker';
@import 'dropdowns';
@import 'empty-result-description-component';
@import 'filter-column';
@import 'filter-sorting';
@import 'forms';
@import 'input-group';
@import 'layout-full-page';
@import 'layout-auth-page';
@import 'lists';
@import 'list-result-counter';
@import 'material/material';
@import 'modals';
@import 'navigation';
@import 'pagination';
@import 'quill';
@import 'tables';
@import 'tags';
@import 'toastr';
@import 'typo';
@import 'utilities';
@import 'template_tree';
@import 'scrollbar';
