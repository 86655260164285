:root {
  --scrollbar-background: #eee;
  --scrollbar-foreground: #aaa;

  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: var(--scrollbar-foreground);
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-background);
  }
}
