@import "bootstrap-variables";

.filter-sorting {
  .custom-select {
    background: none;
    background-color: $white;
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
    border: $input-btn-border-width solid map-get($theme-colors, 'dark');
    border-right: none;
    text-transform: uppercase;
    font-weight: $btn-font-weight;
    color: map-get($theme-colors, 'dark');
  }
}
