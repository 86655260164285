@import 'bootstrap-variables';

.mat-form-field {
  width: 100%;
  margin-bottom: 1rem;
}

.mat-form-field-required-marker {
  display: none;
}

.mat-form-field-subscript-wrapper {
  font-size: 12px;
}

.mat-form-field-appearance-fill.mat-form-field-readonly {
  .mat-select-placeholder,
  textarea::placeholder,
  input::placeholder {
    opacity: 0;
  }

  .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6);
  }

  .mat-input-element {
    color: rgb(25, 28, 31);
  }

  .mat-select-arrow-wrapper,
  .mat-form-field-underline,
  .mat-form-field-suffix {
    display: none;
  }
}

.no-bottom-padding {
  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field {
    margin-bottom: 0;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    bottom: 0;
  }
}

.mat-form-field-appearance-fill:not(.mat-form-field-readonly):not(.mat-form-field-disabled).ng-touched.ng-valid.has-warnings {
  .mat-form-field-label, .mat-hint {
    color: theme-color-level('warning', 1);
  }

  .mat-form-field-underline {
    &::before, .mat-form-field-ripple {
      height: 2px;
      background-color: theme-color-level('warning', 1);
    }
  }
}

app-open-tenant-vat-declaration-edit {
  .mat-form-field {
    margin-bottom: 0 !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }
}

.mat-radio-button,
.form-control-label {
  font-size: 13px;
  font-weight: 600;
}

.mat-checkbox-layout {
  margin-bottom: 0;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-control-xs {
  .mat-form-field-infix {
    padding: 5px 0 !important;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
}

.mat-radio-button {
  margin-right: 10px;
}

// Form fields without labels (smaller height)

.mat-paginator .mat-form-field,
.mat-form-field.mat-no-label {
  .mat-form-field-flex {
    align-items: center;
  }

  .mat-form-field-flex,
  .mat-form-field-infix {
    padding-top: 0;
  }

  .mat-select-arrow-wrapper {
    transform: translateY(0);
  }
}

// HINT: class ONLY for custom errors below material controls, not handled by <mat-error> component
.error-message {
  color: map-get($theme-colors, 'danger');
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 0;

  &:not(.static) {
    position: relative;
    bottom: 28px;
    left: 10px;
    height: 0;
  }
}

.mat-checkbox {
  label {
    align-items: center !important;
  }

  &.readonly {
    .mat-checkbox-frame {
      opacity: 0.2;
    }

    .mat-checkbox-background {
      opacity: 0.5;
    }
  }
}

.mat-radio-button {
  &.readonly {
    .mat-radio-outer-circle {
      opacity: 0.5;
    }

    .mat-radio-inner-circle {
      opacity: 0.5;
    }
  }
}

.no-error-control.mat-form-field.ng-invalid {
  .mat-form-field-ripple {
    background-color: initial;
  }

  .mat-input-element {
    caret-color: initial;
  }

  .mat-select-arrow {
    color: initial;
  }
}

// Colored dropdowns styling

.task-select,
.document-select {
  .mat-form-field-infix {
    border-top: none;
  }

  .mat-form-field-flex {
    padding-top: 0 !important;
    border-radius: $border-radius !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-select-value {
    color: $white;
  }

  .mat-form-field-underline {
    display: none;
  }
}

.task-option {
  &.mat-selected {
    background: none;
  }

  &.pending {
    background-color: $red !important;

    &:hover {
      background-color: rgba($red, 0.85) !important;
    }
  }

  &.in_progress {
    background-color: $yellow !important;

    &:hover {
      background-color: rgba($yellow, 0.85) !important;
    }
  }

  &.on_hold {
    background-color: $blue !important;

    &:hover {
      background-color: rgba($blue, 0.85) !important;
    }
  }

  &.done {
    background-color: $green !important;

    &:hover {
      background-color: rgba($green, 0.85) !important;
    }
  }
}

.task-select {
  &.pending {
    .mat-form-field-flex {
      background-color: $red !important;
      border-color: $red;
    }
  }

  &.in_progress {
    .mat-form-field-flex {
      background-color: $yellow !important;
      border-color: $yellow;
    }
  }

  &.on_hold {
    .mat-form-field-flex {
      background-color: $blue !important;
      border-color: $blue;
    }
  }

  &.done {
    .mat-form-field-flex {
      background-color: $green !important;
      border-color: $green;
    }
  }
}

.mat-form-field-aside {
  display: flex;
  align-items: center;
  padding-bottom: 1.34375em;
}

.selected-document {
  ::ng-deep .mat-select-arrow {
    color: #fff !important;
  }

  ::ng-deep span {
    color: #fff;
    font-weight: bold;
  }

  .mat-select-arrow {
    color: #fff !important;
  }
}

.document-select {
  color: $white;
  width: 150px;

  &.invalid {
    .mat-form-field-flex {
      background-color: $danger !important;
      border-color: $danger;
    }
  }

  &.pending {
    .mat-form-field-flex {
      background-color: $red !important;
      border-color: $red;
    }
  }

  &.approved, &.waiting {
    .mat-form-field-flex {
      background-color: $yellow !important;
      border-color: $yellow;
    }
  }

  &.rejected {
    .mat-form-field-flex {
      background-color: $blue !important;
      border-color: $blue;
    }
  }

  &.paid {
    .mat-form-field-flex {
      background-color: $green !important;
      border-color: $green;
    }
  }

  &.reconciled {
    .mat-form-field-flex {
      background-color: $green-light !important;
      border-color: $green-light;
    }
  }
}

.document-option {
  color: $white;

  &.mat-selected {
    background: none;
    color: $white !important;
  }

  &.invalid {
    background-color: $danger !important;

    &:hover {
      background-color: rgba($danger, 0.85) !important;
    }
  }

  &.pending {
    background-color: $red !important;

    &:hover {
      background-color: rgba($red, 0.85) !important;
    }
  }

  &.approved, &.waiting {
    background-color: $yellow !important;

    &:hover {
      background-color: rgba($yellow, 0.85) !important;
    }
  }

  &.rejected {
    background-color: $blue !important;

    &:hover {
      background-color: rgba($blue, 0.85) !important;
    }
  }

  &.paid {
    background-color: $green !important;

    &:hover {
      background-color: rgba($green, 0.85) !important;
    }
  }

  &.reconciled {
    background-color: $green-light !important;

    &:hover {
      background-color: rgba($green-light, 0.85) !important;
    }
  }
}

.custom-control-no-padding {
  padding: 0 !important;
  .mat-checkbox-layout {
    white-space: initial;
  }
}

.mat-checkbox-wrapped-label {
  .mat-checkbox-layout {
    white-space: initial !important;
  }
}

fieldset.readonly,form.readonly {
  .mat-form-field {
    pointer-events: none;
  }

  button,
  .mat-form-field-underline{
    display: none;
  }

  .mat-select-arrow {
    visibility: hidden;
  }
}

// Forms style adjustments for Firefox

@-moz-document url-prefix() {
  app-textarea .mat-form-field-wrapper {
    padding-bottom: 1rem;
  }

  input.mat-input-element {
    height: 1rem;
  }
}

.mat-form-field {
  .icon-success {
    color: $green;
  }

  .icon-error {
    color: $red;
  }
}

select {
  background: transparent !important;
}

.mat-checkbox-checked .mat-checkbox-background{
  background-color: var(--brand_color) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--brand_color) !important;
}

.mat-radio-inner-circle{
  background-color: var(--brand_color) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: var(--brand_color) !important;
  background-color: var(--brand_color) !important;
}

.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-indeterminate .mat-checkbox-background{
  background-color: var(--brand_color) !important;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  box-shadow: none !important;
  border-color: var(--brand_color) !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.mat-checked .mat-slide-toggle-bar{
  background-color: var(--brand_color)!important;
  filter: brightness(150%)!important;
}

.mat-checked .mat-slide-toggle-thumb{
  background-color: var(--brand_color)!important;
  filter: brightness(80%)!important;
}
