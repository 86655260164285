$status-colors: (
  'active': $green,
  'archived': $gray-400,
  'classification_done': $green,
  'classification_in_progress': $yellow,
  'classification_pending': $yellow,
  'done': $green,
  'downloading': $yellow,
  'draft': $yellow,
  'approved': $yellow,
  'extraction_done': $green,
  'extraction_in_progress': $yellow,
  'failed': $red,
  'rejected': $blue,
  'manual_classification_in_progress': $yellow,
  'manual_classification_required': $yellow,
  'manual_extraction_in_progress': $yellow,
  'manual_extraction_required': $yellow,
  'manual_qa_in_progress': $yellow,
  'manual_qa_required': $yellow,
  'merge_in_progress': $yellow,
  'paid': $green,
  'reconciled': $green-light,
  'paused': $red,
  'red': $red,
  'pending': $red,
  'in_progress': $yellow,
  'preview_in_progress': $yellow,
  'processing': $yellow,
  'processing_done': $yellow,
  'review': $red,
  'stored': $yellow,
  'upload_failed': $red,
  'waiting': $yellow,
  'pending-approval': $accounto_orange,
  'invalid': $danger,
);

.badge {
  padding: 4px 6px;
}

.badge-dark {
  background: black;
}

.badge-white {
  background-color: $white;
  border-color: $white;
  color: $gray-700;

  &:hover:not([disabled]) {
    background-color: $gray-100;
    border-color: $gray-100;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-200
  }
}

.badge-outline-white {
  background-color: $white;
  border-color: $gray-700;
  color: $gray-700;

  &:hover:not([disabled]) {
    background-color: $gray-100;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-200
  }
}

@each $color, $value in $status-colors {
  .badge.#{$color} {
    @include badge-variant($value);
    color: $white;
  }
}
