.caret {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: all linear 0.25s;
}

.open a[dropdowntoggle] .caret {
  transform: rotate(180deg);
}

.dropdown-menu {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.no-max-width-menu {
  max-width: initial !important;
}

.dropdown-header {
  color: $gray-700;
  font-weight: bold;
}

.dropdown-item {
  color: $gray-700;
}

.dropdown-icon {
  margin-right: 10px;
  position: relative;
  top: 1px;
}

.dropdown-second-line {
  margin-left: 28px;
  display: block;
}

.dropdown-list-item {
  &:not(:last-of-type)::after {
    content: '|';
    padding: 4px;
    font-weight: normal;
  }

  &.active {
    font-weight: bold;
  }
}
