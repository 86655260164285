.navigation {
  background-color: var(--navigation_background_color) !important;
  line-height: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  transition: 0.1s width ease-in-out;
  transition: 0.1s overflow-x ease-in-out;
  padding: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: $sidebar-width;

  a {
    text-decoration: none !important;
  }

  .ng-fa-icon {
    font-size: 0.6875rem;
  }

  .nav-item-intended {
    margin-right: 12px;
  }

  ul.nav {
    flex-direction: column;
    list-style: none;

    li.title {
      color: rgba($white, 50%);
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 400;
      padding: 10px 26px;
      vertical-align: middle;
    }

    li.nav-item {
      overflow: hidden;
      position: relative;
      border-bottom: 1px solid rgba(255,255,255,0.05);

      &:focus {
        outline: 0 !important;
      }

      a.nav-dropdown-toggle {
        position: relative;
      }

      a.nav-link {
        cursor: pointer;
        border-left:4px solid transparent; // HACK - avoid left border jump when border is added for open link
        color: var(--navigation_font_color);
        font-size: $navigation-font-size;
        font-weight: $navigation-font-weight;
        padding: 16px 16px 16px 23px;

        &:hover {
          background-color: var(--navigation_background_item_active_color);
          color: var(--navigation_font_color);
        }
      }
    }

    .arrow {
      float: right;
      padding-top: 1px;
      transition: transform 0.1s ease-in-out;
    }
  }

  ul.nav-dropdown-content > li.nav-item > a.nav-link.active,
  ul.nav > li.nav-item > a.nav-link.active {
    background-color: var(--navigation_background_item_active_color);
    border-left: 4px solid var(--brand_color);
    color: var(--navigation_font_color);
  }

  li.nav-item.open {
    a.nav-link {
      border-left: $navigation-item-border-active;
      padding-left: 21px;
      transition: 0.1s border ease-in-out, 0.1s padding ease-in-out;
    }

    .nav-dropdown-toggle {
      padding-left: 21px;
    }
  }

  ul.nav-dropdown-content {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    overflow-y: hidden;
    padding: 0;

    li.nav-item {
      overflow-x: hidden;
      font-size: $font-size-nav-item;

      .nav-item-header {
        padding-left: 47px;
      }

      a.nav-link {
        padding: 10px 16px 10px 47px;
        transition: 0.1s border ease-in-out;

        &:hover {
          background-color: var(--navigation_background_item_active_color);
        }
      }
    }
  }

  .border-none-nav-item {
    border: 0 !important;
  }

  .line-separator {
    background: rgba(255,255,255, 0.05);
    margin-left: -20px;
    margin-right: -20x;
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  app-logo {
    padding: 27px;
  }
}
