.input-group-xs > .form-control:not(textarea),
.input-group-xs > .custom-select {
  height: $input-height-xs;
}

.input-group-xs > .form-control,
.input-group-xs > .custom-select,
.input-group-xs > .input-group-prepend > .input-group-text,
.input-group-xs > .input-group-append > .input-group-text,
.input-group-xs > .input-group-prepend > .btn,
.input-group-xs > .input-group-append > .btn {
  padding: $input-padding-y-xs $input-padding-x-xs;
  font-size: $input-font-size-xs;
  line-height: $input-line-height-xs;
  @include border-radius($input-border-radius-xs);
}

.input-group-xs > .custom-select {
  padding-right: $custom-select-padding-x + $custom-select-indicator-padding;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  @include border-right-radius(0);
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  @include border-left-radius(0);
}
