.table {
  thead {
    &.thead-dark {
      th {
        // background-color: $gray-700;
        // border-color: $gray-700;
        background-color: lighten(#3e515b, 12);
        border-color: lighten(#3e515b, 12);
      }
    }
  }

  &.no-border th,
  &.no-border td {
    border: none;
  }

  &.no-first-border tr:first-child td {
    border: none;
  }

  tr.has-second-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.second-row {
    td {
      border-top: none;
      padding-top: 0;
    }
  }

  tr.top-row {
    td {
      padding-bottom: 0;
    }
  }

  tr.middle-row {
    td {
      border-top: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  tr.bottom-row {
    td {
      border-top: none;
      padding-top: 0;
      padding-bottom: 0.75rem;
    }
  }

  tr.dim {
    td:not(.nodim) {
      opacity: 0.3;
    }
  }

  tr.top td,
  td.top,
  th.top {
    vertical-align: top !important;
  }

  &.vertical-center th,
  &.vertical-center td {
    vertical-align: middle;
  }

  th,
  td {
    vertical-align: middle;
  }

  tr.no-border td {
    border-top: none;
  }

  h3 {
    margin-bottom: 0;
  }
}

table .list-image {
  height: 70px;
  width: auto;
  border: 1px solid $gray-100;
}

.padding-sm {
  td,
  th {
    padding: 0.2rem;
  }
}

.padding-md {
  td,
  th {
    padding: 0.4rem;
  }
}

.padding-lg {
  td,
  th {
    padding: 0.8rem;
  }
}

.padding-y-0 {
  td,
  th {
    padding: 0.2rem 0;
  }
}

.padding-y-md {
  td:first-child,
  th:first-child {
    padding-left: 0.8rem;
  }

  td:last-child,
  th:last-child {
    padding-right: 0.8rem;
  }
}

.btn-cell {
  padding-bottom: 0.5rem !important;
}

.border-top-2 {
  border-top: 2px solid $gray-300;
}

table.no-wrap {
  td,
  th {
    white-space: nowrap;
  }
}

table.first-column-xs tr td:first-child,
table.first-column-xs tr th:first-child {
  width: 50px;
  padding-left: 0;
}

table.first-column-sm tr td:first-child,
table.first-column-sm tr th:first-child {
  width: 100px;
  padding-left: 0;
}

table.first-column-md tr td:first-child,
table.first-column-md tr th:first-child {
  width: 150px;
  padding-left: 0;
}

table.first-column-lg tr td:first-child,
table.first-column-lg tr th:first-child {
  width: 200px;
  padding-left: 0;
}

table.first-column-xl tr td:first-child,
table.first-column-xl tr th:first-child {
  width: 250px;
  padding-left: 0;
}

tr.padding-left-10 {
  padding-left: 10px;
  th {
    text-indent: 10px;
  }
}

th.rotate {
  height: 150px;
  white-space: nowrap;
  padding: 0;
}

th.rotate > div {
  transform: translate(0, -0.5rem) rotate(315deg);
  width: 30px;
}

th.rotate > div > span {
  padding: 0.75rem;
}

tr.active td {
  background-color: #d3f1fb;
}

tr.inactive td {
  color: rgba(0, 0, 0, .4);
}
