@import 'bootstrap-variables';

// Link helper
.pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.no-pointer-events {
  pointer-events: none;
}

// Image helper
.full-img-fluid {
  width: 100%;
  height: auto;
}

// Scroll Container
.scroll-container {
  overflow-y: scroll;
  overflow-x: hidden;
}

.image-scroll-container {
  overflow-y: scroll !important;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x {
  overflow-x: auto;
}

// Backgrounds
.bg-dark-light {
  background-color: #dce0e2;
}

.bg-light {
  background-color: lighten($gray-100, 1%) !important;
  color: $black;
}

// Borders
.border-top-5 {
  border-top-width: 5px !important;
}

.border-bottom-5 {
  border-bottom-width: 5px !important;
}

// Text helpers
.meta {
  font-size: 0.625rem;
}

// Due date
.overdue {
  color: $red;
}

.on-time {
  color: $green;
}

// Text helper
.u-br {
  white-space: pre-wrap;
}
.u-nowrap {
  white-space: nowrap;
}
.u-wordwrap {
  word-wrap: break-word;
}
.u-whitespace-normal {
  white-space: normal !important;
}
.u-wordwrap-a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.stretched {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.link {
  color: $cyan !important;
  cursor: pointer;

  &:hover {
    color: $purple !important;
  }
}
