html,
body,
app-root {
  display: block;
  height: 100%;
}

body {
  overflow-x: hidden;
}

/*
 default display styling for router components that allows
 proper page title sticky behavior
*/
router-outlet ~ * {
  display: block;
}

.app,
app-full-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

ng-alt-sidebar-container {
  flex: 1 1 auto;
}

.ng-alt-sidebar-content {
  min-width: 1000px;
}

.ng-alt-sidebar__content {
  background-color: var(--content_primary_background_color);
}

.ng-alt-sidebar {
  background: var(--navigation_background_item_dark_color);
  z-index: $zindex-navigation !important;

  .navigation-container {
    height: 100%;
    min-height: 100%;
    width: $sidebar-width;
    max-width: $sidebar-width;
  }
}

.header {
  background-color: $blue;
  flex: 0 1 auto;
}

.app-logo {
  height: 100%;
  width: auto;
}

.error-page__logo {
  svg {
    width: 100px;
    height: 25px;
    margin-left: -4px;
  }
}

.navbar {
  padding: 0.75rem 0.625rem 0.75rem 1rem;
}

.navbar-user {
  font-size: 0.6875rem;

  a fa-icon {
    font-size: 0.8125rem;
  }

  .fa-layers {
    margin-right: 0.5rem !important;
  }
}

.app-main {
  background-color: var(--content_primary_background_color);
  padding: 0 2.5rem 1rem;

  .overlap {
    display: none;
    background: $body-bg;
    height: 50px;
    margin: -1rem -1rem -40px -1rem;
  }

  .claim {
    font-size: 1rem;
    margin-top: -7px;
  }
}

.card-container {
  @extend .card;
  max-width: map_get($grid-breakpoints, 'xl');
}
