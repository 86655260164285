$control-height: 31px;

.form-control {
  height: $control-height;
  background-color: $input-bg;
  border: none;
  border-bottom: $input-border-width solid $input-border-color;
  padding: 0.325rem 0.6rem;
  font-size: 0.875rem;

  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-bottom-color: $input-btn-focus-color;
    box-shadow: none;
  }
}

@include custom-form-validation-state('valid', $form-feedback-valid-color);
@include custom-form-validation-state('invalid', $form-feedback-invalid-color);

.form-group {
  &.has-error {
    label {
      color: $danger;
    }
  }
}

.input-group-text {
  border: none;
  border-bottom: $input-border-width solid $input-group-addon-border-color;
}

.datepicker-with-prediction-component .input-group-text,
.prediction-wrapper .input-group-text {
  color: $white;
  font-size: 0.6875rem !important;

  &.success {
    background-color: $success;
  }
  &.warning {
    background-color: $warning;
  }
  &.danger {
    background-color: $danger;
  }
}

.input-group-sm .custom-file {
  height: auto !important;
}

booking-section,
app-repeat-section {
  .repeat-section {
    padding-top: 15px;
    padding-bottom: 10px;
    position: relative;

    .repeat-section-remove {
      position: absolute;
      top: 5px;
      right: -4px;
      z-index: 999;

      .close {
        color: $red;
        opacity: 0.75 !important;
        font-size: 1.2rem;

        &:hover {
          opacity: 1 !important;
        }

        &.up,
        &.down {
          color: $blue;
        }
      }
    }
  }

  .repeat-section-button {
    padding-bottom: 10px;
  }
}

booking-section {
  .border-bottom {
    border-bottom: 4px solid $blue !important;
  }

  .section-item {
    padding-top: 10px;
  }
}

.custom-control {
  padding-left: 1.5rem;

  &.custom-switch {
    padding-left: 3.25rem;
  }
}

.custom-control-label::before,
.custom-control-label::after {
  left: -1.5rem;
}

.input-group-append {
  margin-left: -1px;
}

.custom-select-sm {
  height: 39px !important;
}

.custom-select-xs {
  height: 30px !important;
  padding: 0.25rem 0.75rem !important;
  font-size: $custom-select-font-size-xs;
}

.custom-select {
  &.status {
    $custom-select-indicator: str-replace(
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$white}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
      '#',
      '%23'
    );
    background: $custom-select-indicator no-repeat right $custom-select-padding-x center / $custom-select-bg-size;
    color: $white;
  }
  &.pending {
    background-color: $red;
    border-color: $red;
  }

  &.invalid {
    background-color: $danger;
    border-color: $danger;
  }

  &.in_progress {
    background-color: $yellow;
    border-color: $yellow;
  }

  &.on_hold {
    background-color: $blue;
    border-color: $blue;
  }

  &.done {
    background-color: $green;
    border-color: $green;
  }
}

.checkbox-with-fixed-width {
  .custom-checkbox {
    display: inline-block;
    width: 50px;
  }

  label {
    width: 100%;
  }
}
