@use "sass:math";

@import 'variables';

.card {
  margin-bottom: $spacer;
  border-radius: 6px !important;

  .card-header-toggle,
  .card-header,
  .card-body,
  .card-footer {
    padding: $padding-y $padding-x;
  }

  &.card-selectable {
    background: $input-bg;
    border-bottom: $input-border-width solid $input-border-color;
    box-shadow: none;
    cursor: pointer;

    .card-body {
      padding: 0.5rem;
    }

    &:hover {
      background-color: darken($input-bg, 3);
      border-bottom-color: $input-btn-focus-color;
    }
  }

  border: 0px solid transparent;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* TODO JHH: Save this can be deleted? Don't see side effects yet. */
    /* font-size: 0.875rem; */
  }

  .card-header {
    border-bottom: none;
    font-weight: 700;
    background-color: $white;
    font-size: $font-size-header;
    padding-bottom: 0;
    border-radius: 0.15rem;

    a:not(.btn) {
      color: $white;
      color: $text-color;

      &:hover {
        color: darken($white, 10);
        color: darken($text-color, 10);
      }
    }

    h3 {
      line-height: 1.5;
    }
  }

  &.form-card {
    max-width: 800px;
  }

  &.dark-header {
    .card-header {
      background-color: $gray-700;
      color: $white;
      padding: $padding-y $padding-x;
    }
  }

  &.dashboard {
    .card-header {
      background-color: $gray-700;
      color: $white;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      padding-left: 0.8rem;
      padding-right: 15px;
    }

    .card-body {
      padding: 0;

      &.no-table {
        padding: 0 0.8rem;
      }
    }

    .badge {
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  @each $color, $value in $theme-colors {
    &.bg-header-#{$color} {
      background-color: $white !important;

      .card-header {
        background-color: $value;
        color: $white;
        a {
          color: $white;

          &:hover {
            color: darken($white, 10);
          }
        }
      }
    }
  }

  @each $color, $value in $theme-colors {
    &.bg-#{$color} {
      background-color: $value !important;
      color: $white;

      .card-header {
        background-color: darken($value, 5);
        color: $white;
      }

      .card-footer {
        background-color: $value !important;
        border-top: 1px solid darken($value, 5);
        color: $white;
      }
    }
  }

  .card-footer {
    background-color: $white;
  }

  &.bg-light {
    color: $black;
  }

  &.success-border {
    border-top:6px solid darkseagreen;
  }

  &.error-border {
    border-top:6px solid indianred;
  }
}

.card-plain {
  box-shadow: none;

  .card-footer {
    border-top: none;
  }

  &.no-padding {
    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }
}

// Card Decks

// number of cards per line for each breakpoint
$cards-per-line: (
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 4,
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    $current-cards-per-line: map-get($cards-per-line, $name);
    $current-width: math.div(100, $current-cards-per-line);

    .card-deck-responsive .card {
      flex: 0 0 calc(#{$current-width + '%'} - #{$grid-gutter-width});
      margin-bottom: 20px;
    }
  }
}

// Card Tabs

.nav.nav-tabs.card-tabs {
  border-bottom: 5px solid $white;
  margin-top: -4px;
  position: relative;
  z-index: 50 !important;

  .nav-item {
    border-radius: $border-radius;
    border: none;
    margin-bottom: -2px;
    margin-right: 2px;
    padding-top: 4px;

    .nav-link {
      background-color: var(--content_secondary_background_color);
      border: none;
      border-bottom: 2px solid $white;
      font-weight: 600;

      &:hover {
        background-color: var(--brand_color);
      }

      &.active {
        background-color: $white;
        border: none;
        border-bottom: 2px solid $white;
        box-shadow: 0 -9px 9px 0 #e6e6e6;
      }
    }
  }
}


.card-dark {
  background: $accounto-dark_blue;
  color: $white !important;

  .mat-checkbox-label,
  .mat-input-element,
  .mat-form-field-label,
  .mat-datepicker-toggle-default-icon,
  .mat-radio-label-content {
    color: $white !important;
  }

  .mat-checkbox-frame,
  .mat-radio-outer-circle {
    border-color: $white !important;
  }

  :not(.mat-radio-checked) .mat-radio-inner-circle {
  background-color: transparent !important;
  }

  .mat-radio-checked .mat-radio-inner-circle {
    background-color: $white !important;
  }

  .mat-checkbox-checked .mat-checkbox-background {
    background: #838383 !important;
  }

  .mat-radio-label-content {
    width: 100%;
  }

  .mat-radio-label {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
