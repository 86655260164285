@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/typography/typography-utils';
@use '../core/style/list-common';


// Include this empty mixin for consistency with the other components.
@mixin color($config-or-theme) {}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
      theming.get-typography-config($config-or-theme));
  .mat-grid-tile-header,
  .mat-grid-tile-footer {
    @include list-common.base(typography-utils.font-size($config, caption));
    font-size: typography-utils.font-size($config, body-1);
  }
}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-grid-list') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
