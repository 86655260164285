// Global Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: true;

// Grid
$grid-gutter-width: 20px !default;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Colors
$accounto_orange: #FF6C00;
$blue: #25BBEB;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f44336;
$orange: #f8cb00;
$yellow: #FEC02F;
$green: #79C092;
$green-light: #2de800;
$teal: #20c997;
$cyan: #00BFB3;
$light: #dbdcdc;
$white: #ffffff;
$black: #191C1F;
$accounto-dark_blue: #003444;
$light-gray: #F9F9F9;
$dark-gray: #787878;
$text-color: #29363d;
$text-color: darken($blue, 10%);
$corporate-color: $blue;
$background: #f9f9f9;

$gray-25: #fafcfe;
$gray-50: #f3f5f7;
$gray-100: #f0f3f5;
$gray-125: #f9f9f9;
$gray-150: #dde0e2;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #003444;
$gray-750: #344955;
$gray-800: #29363d;
$gray-900: #151b1e;

$colors: (
  'accounto_orange': $accounto_orange,
  'light-gray': $light-gray,
  'dark-gray': $dark-gray,
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
  'text-color': $text-color,
  'corporate-color': $corporate-color,
  'background': $background,
);

$theme-colors: (
  'primary': $accounto_orange,
  'secondary': $dark-gray,
  'success': $green,
  'info': $blue,
  'warning': $yellow,
  'danger': $red,
  'light': $light,
  'dark': $gray-700,
);

// change threshold for contrast calculation, $blue
// should still have white fonts. default: 150.
$yiq-contrasted-threshold: 180;

// Body

$body-bg: $gray-700;
$body-color: $black;

// Fonts

$font-family-sans-serif: 'Overpass', sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.8125rem;
$font-size-xs: 0.75rem;

// Line heights

$line-height-base: 1.5 !default;
$line-height-xs: $line-height-base;

// Border

$border-width: 1px;
$border-color: $gray-200;
$border-radius: 2px;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
$border-radius-xs: $border-radius;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  1px: (
    1px,
  ),
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  33: 33.333%,
  50: 50%,
  75: 75%,
  100: 100%,
) !default;

// Paragraphs

$paragraph-margin-bottom: 1rem !default;

// z-index hierarchy

$zindex-layout: 980;
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-navigation: 102;
$zindex-float-view: 103;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-pace: 1080;
$zindex-page-title: 101;

// Components

$component-active-color: $white;
$component-active-bg: $corporate-color;

$caret-width: 0.3em;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0 9px 0 rgba(200, 200, 200, 1) !default;
$box-shadow-hover: 0 0 18px 0 rgba($black, 0.17) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-border: 0 0 2px 0 $gray-200;

// Buttons

$input-btn-border-width: 2px !default;

$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 3rem !default;

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 2.25rem !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 1rem !default;

$input-btn-padding-y-xs: .25rem !default;
$input-btn-padding-x-xs: .5rem !default;

$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $line-height-xs !default;

$btn-font-weight: 700 !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;

// Buttons borders

$btn-border-radius: 5px;
$btn-border-radius-lg: $btn-border-radius;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-xs: $btn-border-radius;

// Forms

$input-bg: lighten($gray-100, 1%);

$input-color: $gray-700;
$input-border-color: $gray-400;
$input-border-width: 2px;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075);

$input-btn-focus-color: $component-active-bg;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-group-margin-bottom: 0;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;

$custom-control-gutter: 1.5rem;
$custom-control-spacer-x: 1rem;

$custom-control-indicator-size: 1rem;
$custom-control-indicator-bg: $gray-300;
$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-label-disabled-color: $gray-600;

$custom-select-padding-y: 0.375rem !default;
$custom-select-padding-x: 0.75rem !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $light !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-border-color: $light !default;
$custom-select-border-radius: $btn-border-radius !default;
$custom-select-box-shadow: inset 0 1px 2px rgba($black, 0.075) !default;

$custom-select-focus-border-color: $light !default;
$custom-select-focus-box-shadow: none;
$custom-select-font-size-xs: 0.8125rem;

$input-height-border: $input-border-width * 2 !default;

$input-font-size-xs: $font-size-xs !default;

$input-line-height-xs: $input-btn-line-height-xs !default;
$input-border-radius-xs: $border-radius-xs !default;

$input-padding-y-xs: $input-btn-padding-y-xs !default;
$input-padding-x-xs: $input-btn-padding-x-xs !default;

$input-height-inner-xs: ($input-btn-font-size-xs * $input-btn-line-height-xs) + ($input-btn-padding-y-xs * 2) !default;
$input-height-xs: calc(#{$input-height-inner-xs} + #{$input-height-border}) !default;

// Badges

$badge-font-size: 0.8125rem !default;
$badge-font-weight: 600;
$badge-padding-y: 0.25em !default;
$badge-padding-x: 0.4em !default;
$badge-border-radius: $border-radius !default;

$badge-pill-padding-x: 0.6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;
