.alert {
  a {
    text-decoration: $link-hover-decoration !important;

    &:hover {
      text-decoration: none !important;
    }
  }

  &.full-alert {
    background-color: $gray-600;
    border-color: $gray-600;
    border-radius: 0;
    color: $white;
    font-weight: normal;
  }
}
