@use 'sass:map';
@use '../core/style/private';
@use '../core/theming/theming';
@use '../core/typography/typography';
@use '../core/typography/typography-utils';


@mixin color($config-or-theme) {
  $config: theming.get-color-config($config-or-theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  .mat-dialog-container {
    @include private.private-theme-elevation(24, $config);
    background: theming.get-color-from-palette($background, dialog);
    color: theming.get-color-from-palette($foreground, text);
  }
}

@mixin typography($config-or-theme) {
  $config: typography.private-typography-to-2014-config(
      theming.get-typography-config($config-or-theme));
  .mat-dialog-title {
    @include typography-utils.typography-level($config, title);
  }
}

@mixin _density($config-or-theme) {}

@mixin theme($theme-or-color-config) {
  $theme: theming.private-legacy-get-theme($theme-or-color-config);
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-dialog') {
    $color: theming.get-color-config($theme);
    $density: theming.get-density-config($theme);
    $typography: theming.get-typography-config($theme);

    @if $color != null {
      @include color($color);
    }
    @if $density != null {
      @include _density($density);
    }
    @if $typography != null {
      @include typography($typography);
    }
  }
}
