#toast-container > div {
  opacity: 1;
}

.toast-container {
  .toast {
    background-size: 18px;
    padding: 0.5rem 1.25rem 0.5rem 50px;
    right: -0.1rem;
  }

  .toast,
  .toast-title,
  .toast-message {
    font-size: 0.875rem;
  }

  .toast-close-button {
    background: transparent;
    border: none;
    padding: 0;
  }

  .toast-success {
    color: theme-color-level('success', 6);
    background-color: theme-color-level('success', -8);
    border: 1px solid theme-color-level('success', -7);

    .toast-close-button:hover {
      color: theme-color-level('success', 6);
    }
  }

  .toast-error {
    color: theme-color-level('danger', 6);
    background-color: theme-color-level('danger', -10);
    border: 1px solid theme-color-level('danger', -9);

    .toast-close-button:hover {
      color: theme-color-level('danger', 6);
    }
  }

  .toast-info {
    color: theme-color-level('info', 6);
    background-color: theme-color-level('info', -10);
    border: 1px solid theme-color-level('info', -9);

    .toast-close-button:hover {
      color: theme-color-level('info', 6);
    }
  }

  .toast-warning {
    color: theme-color-level('warning', 6);
    background-color: theme-color-level('warning', -10);
    border: 1px solid theme-color-level('warning', -9);

    .toast-close-button:hover {
      color: theme-color-level('warning', 6);
    }
  }
}
