quill-editor {
  .ql-tooltip {
    left: 10px !important;
    z-index: 2;
  }

  .ql-toolbar button {
    &:hover, &.ql-active {
      .ql-stroke {
        stroke: $corporate-color !important;
      }
    }
  }

  .ql-toolbar.ql-snow {
    border: 0;
  }

  .ql-container {
    resize: none;
  }

  .ql-editor {
    font-family: $font-family-sans-serif;
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.87);

    &.ql-blank:before {
      color: rgba(0, 0, 0, 0.42);
      font-style: normal;
    }

    ol, ul {
      padding-left: 0;
    }

    p, ol, ul {
      margin-bottom: 0.5rem;
    }

    li:not(.ql-direction-rtl) {
      padding-left: 1rem;
      &.ql-indent-1 { padding-left: 2rem; }
      &.ql-indent-2 { padding-left: 3rem; }
      &.ql-indent-3 { padding-left: 4rem; }
      &.ql-indent-4 { padding-left: 5rem; }
      &.ql-indent-5 { padding-left: 6rem; }
    }
  }

  .ql-container.ql-snow {
    border: 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.42);
      height: 1px;
    }

    &:hover, &:focus, &:active, &:focus-within {
      &:after {
        height: 2px;
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }

  &.ng-touched.ng-invalid .ql-container.ql-snow:after {
    height: 2px;
    background-color: $danger;
  }

  &[disabled] .ql-editor {
    background-color: rgba(0, 0, 0, 0.02);
    color: rgba(0, 0, 0, 0.6);
  }

  &.ql-readonly .ql-editor {
    background-color: rgba(0, 0, 0, 0.02);
  }

  &[disabled] .ql-toolbar,
  &.ql-readonly .ql-toolbar {
    display: none;
  }

  &[disabled] .ql-container:after,
  &.ql-readonly .ql-blank:before,
  &.ql-readonly .ql-container:after {
    visibility: hidden;
  }
}

app-quill-input quill-editor .ql-toolbar button {
  &:hover, &.ql-active {
    .ql-stroke {
      stroke: var(--brand_color) !important;
    }
    .ql-fill {
      fill: var(--brand_color) !important;
    }
  }
}
