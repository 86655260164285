@import 'bootstrap-variables';
@import 'variables';

// General btn styling

.btn {
  text-transform: uppercase;
}

// Small button definition

.btn-xs {
  white-space: nowrap;
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

// Custom btn bottom paddings

.btn { padding-bottom: $input-btn-padding-y - $input-btn-padding-bottom-offset-fix; }
.btn-sm { padding-bottom: $input-btn-padding-y-sm - $input-btn-padding-bottom-offset-fix; }
.btn-xs { padding-bottom: $input-btn-padding-y-xs - $input-btn-padding-bottom-offset-fix; }
.btn-lg { padding-bottom: $input-btn-padding-y-lg - $input-btn-padding-bottom-offset-fix; }

// Styling icon buttons

.btn fa-icon {
  margin-right: 2px;
}

.btn-icon {
  padding: $btn-padding-y-xs $btn-padding-x-xs;
  transition: $transition-base;

  fa-icon {
    color: inherit;
    margin: 0;
  }

  &:hover {
    color: $gray-700;
  }

  &:not(:hover) fa-icon {
    color: $dark-gray;
  }

  &:focus, &:active:focus {
    box-shadow: 0 0 0 0.2rem var(--brand_shadow_color) !important;
  }
}

// Styling primary buttons

.btn-outline-primary {
  border-color: var(--brand_color) !important;
  background: transparent !important;
  color: var(--brand_color) !important;

  &:hover {
    background: var(--brand_color) !important;
    color: #fff !important;
  }

  &:focus, &:active:focus {
    box-shadow: 0 0 0 0.2rem var(--brand_shadow_color) !important;
  }
}

.btn-primary {
  color: #fff !important;
  background: var(--brand_color) !important;
  border-color: var(--brand_color) !important;

  &:focus, &:active:focus  {
    box-shadow: 0 0 0 0.2rem var(--brand_shadow_color) !important;
  }
}

.btn-outline-primary:not(:disabled).active {
  background: var(--brand_color) !important;
  color: #fff !important;
}

.btn-primary[disabled] {
  @extend .btn-outline-primary;
}

// Styling dark buttons

.btn-outline-dark {
  border-color: black !important;
  color: black !important;

  &:hover {
    background: black !important;
    color: $white !important;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-600;
  }
}

.btn-outline-dark.active {
  background: black !important;
  color: $white !important;
}

.btn-dark {
  background: black !important;
  color: $white !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-600;
  }
}

// Styling white buttons

.btn-white {
  background-color: $white;
  border-color: $white;
  color: $gray-700;

  &:hover:not([disabled]) {
    background-color: $gray-100;
    border-color: $gray-100;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-200
  }
}

.btn-outline-white {
  background-color: $white;
  border-color: $gray-700;
  color: $gray-700;

  &:hover:not([disabled]) {
    background-color: $gray-100;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem $gray-200
  }
}

// Styling link buttons

.btn-link,
.btn-outline-link {
  font-weight: 700;
  color: var(--brand_color) !important;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:focus, &:active:focus  {
    box-shadow: 0 0 0 0.2rem var(--brand_shadow_color) !important;
  }
}
