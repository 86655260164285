// Base font size = 16px (= 1rem)
//
// px <=> rem calculations:
// 8px = 0.5rem
// 9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem (base)
// 18px = 1.125rem
// 20px = 1.25rem
// 22px = 1.375rem
// 24px = 1.5rem
// 26px = 1.625rem
// 28px = 1.75rem
// 30px = 1.875rem
// 32px = 2rem
// 34px = 2.125rem
// 36px = 2.25rem
// 38px = 2.375rem
// 40px = 2.5rem

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.default-content {
  font-size: $font-size-content;
  text-transform: none;
  font-weight: 500;
}

.product__info__title__label {
  font-weight: bold !important;
}

h3 {
  font-size: $font-size-header;
  font-weight: bold;
}

h4 {
  font-size: $font-size-header-xs;
  font-weight: bold;
}

h5 {
  font-size: $font-size-header-xxs;
}
