// Generates the card color variants
@mixin bg-variant($parent, $color) {
  #{$parent} {
    @include border-radius($card-border-radius-inner $card-border-radius-inner $card-border-radius-inner $card-border-radius-inner);
    color: #fff !important;
    background-color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}

// Needed in order to remove the box-shadow
@mixin custom-form-validation-state($state, $color) {
  .form-control,
  .custom-select {
    .was-validated &:#{$state},
    &.is-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: none;
      }
    }
  }
}
