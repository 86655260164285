@use '@angular/material' as mat;
@import 'bootstrap-variables';

$custom-typography: mat.define-typography-config(
  $font-family: 'Overpass',
  $display-4: mat.define-typography-level(13px, 1.2, 300, $letter-spacing: -0.05em),
  $display-3: mat.define-typography-level(13px, 1.2, 400, $letter-spacing: -0.02em),
  $display-2: mat.define-typography-level(13px, 1.2, 400, $letter-spacing: -0.005em),
  $display-1: mat.define-typography-level(13px, 1.2, 400),
  $headline: mat.define-typography-level(13px, 1.2, 400),
  $title: mat.define-typography-level(13px, 1.2, 500),
  $subheading-2: mat.define-typography-level(13px, 1.2, 400),
  $subheading-1: mat.define-typography-level(13px, 1.2, 400),
  $body-2: mat.define-typography-level(13px, 1.2, 500),
  $body-1: mat.define-typography-level(13px, 1.2, 400),
  $caption: mat.define-typography-level(13px, 1.2, 400),
  $button: mat.define-typography-level(13px, 1.2, 500),
  $input: mat.define-typography-level(13px, 1.2, 400)
);

// **Be sure that you only ever include this mixin once!**
@include mat.core($custom-typography);

@import 'material_forms';
@import 'material_tables';
@import 'material_tabs';

.custom-mat-dialog {
  max-width: 1100px;
}

.mat-dialog-title-container {
  h1 {
    font-size: 18px;
  }

  p {
    font-size: 13px;
  }
}

.mat-expansion-panel-header .mat-content {
  align-items: center;
}

.mat-expansion-panel-header-title {
  font-size: 1.25rem;
  font-weight: 700;
}

.no-address-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.white-spinner.mat-progress-spinner circle, .white-spinner.mat-spinner circle {
  stroke: #fff;
}

.blue-spinner.mat-progress-spinner circle, .blue-spinner.mat-spinner circle {
  stroke: $blue;
}

.orange-spinner.mat-progress-spinner circle, .orange-spinner.mat-spinner circle {
  stroke: var(--brand_color);
}

.onboarding-stepper {
  .mat-horizontal-stepper-header-container {
    background: $light-gray;
    padding-left: 45px;
    padding-right: 45px;
  }

  .mat-horizontal-content-container {
    padding-top: 16px;
    box-shadow: 0 8px 16px rgb(0 52 68 / 10%);
    border: 0 solid transparent;
    border-radius: 2px;
  }

  .cdk-focused,
  .cdk-program-focused,
  .mat-step-header:hover {
    background-color: transparent !important;
  }

  .mat-step-icon-state-edit {
    background: $green;
  }
}

.mat-step-text-label {
  font-size: 14px;
  font-weight: bold;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}


.modal-sm {
  max-width: 400px !important;
}
